import { WithId } from '@padspin/models';

export type CentPropNYListing = {
  slug?: string;
  notes?: string;
  /** 'Owner' */
  user_relation: string;
  rent_amount: number;
  bedrooms?: number;
  bathrooms?: number;
  area?: number;
  earliest_move_in_date: string; // 'Aug 1' 'IMM'
  description?: string;
  floor?: number;
  address: string;
  city: string;
  unit_number: string;
  state: string;
  postal_code: string;
  central_air: boolean;
  dishwasher: boolean;
  doorman: boolean;
  elevator: boolean;
  fireplace: boolean;
  gym: boolean;
  high_ceilings: boolean;
  outdoor: boolean;
  parking: boolean;
  pets: boolean;
  pool: boolean;
  video_url: string;
  washer_dryer: boolean;
  wood_flooring: boolean;
  cross_street: string;
  neighborhood?: string;
  gross: number;
  admin_notes?: string;
  tenant_contact?: string;
};

export type CentPropNYListingID = WithId<CentPropNYListing>;

export type CentPropNyRequest = {
  listings: Array<CentPropNYListing>;
};
